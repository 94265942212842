export function hidePhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return false;
  }

  // 确保手机号码是一个字符串，并去除前后的空格
  phoneNumber = phoneNumber.trim();

  // 如果电话号码长度小于或等于4，则返回原号码
  if (phoneNumber.length <= 4) {
    return phoneNumber; // 返回原号码
  }

  // 取前2位和后2位
  const startPart = phoneNumber.slice(0, 2);
  const endPart = phoneNumber.slice(-2);

  // 中间部分用4个星号替代
  const hiddenPart = '****';

  return `${startPart}${hiddenPart}${endPart}`;
}

export function formatBankCardNumber(cardNumber) {
  // 移除非数字字符
  const cleaned = cardNumber.replace(/\D/g, '');

  // 检查长度
  if (cleaned.length < 4) {
    return cleaned; // 如果长度小于4，直接返回
  }

  // 获取最后4位
  const lastFourDigits = cleaned.slice(-4);

  // 构建格式化的字符串
  const formatted = '**** **** **** ' + lastFourDigits;

  return formatted;
}
