import { defineStore } from 'pinia'
import { BankList, UserInfo, Wallet } from '@/api';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: {
      name: '-',
      avatar: '',
    },
    token: null,
    wallet: {
      balance: '0.00',
    },
    bankData: {
      phone: '',
      bank_owner: '',
      card: '',
      bank_name: '',
      branch: '',
      route: '',
    },
    ipInfo: {
      cityName: '-',
      province: '-'
    },
    lang: 'en_US',
  }),
  actions: {
    setLang(lang) {
      this.lang = lang
    },
    setUser(user) {
      this.user = { ...user }
    },
    setToken(token) {
      this.token = token
    },
    updateUserInfo() {
      UserInfo().then(({ code, data }) => {
        if (code === 200) {
          this.user = data
        }
      })
    },
    updateWallet() {
      Wallet().then(({ data }) => {
        this.wallet = data
      })
    },
    updateBankData() {
      BankList().then(({ code, data }) => {
        if (code === 200 && data) {
          this.bankData = data;
        }
      })
    },
    setIpInfo(ipInfo) {
      this.ipInfo = ipInfo
    }
  },
  persist: true,
})
