import "core-js/modules/es.array.push.js";
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/hods.png';
import _imports_1 from '@/assets/images/shop.png';
import _imports_2 from '@/assets/images/index_.png';
import _imports_3 from '@/assets/images/map1.png';
import _imports_4 from '@/assets/images/cash_in.png';
import _imports_5 from '@/assets/images/cash_out.png';
import _imports_6 from '@/assets/images/menu1.png';
import _imports_7 from '@/assets/images/menu2.png';
import _imports_8 from '@/assets/images/lang.png';
import _imports_9 from '@/assets/images/menu3.png';
import _imports_10 from '@/assets/images/menu4.png';
import _imports_11 from '@/assets/images/menu5.png';
import _imports_12 from '@/assets/images/menu6.png';
import _imports_13 from '@/assets/images/menu7.png';
const _hoisted_1 = {
  class: "home-page"
};
const _hoisted_2 = {
  class: "home-head"
};
const _hoisted_3 = {
  class: "home-head__left"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "home-head__right"
};
const _hoisted_6 = {
  class: "home-title"
};
const _hoisted_7 = {
  class: "home-list"
};
const _hoisted_8 = {
  class: "home-list__title"
};
const _hoisted_9 = {
  class: "home-user"
};
const _hoisted_10 = {
  class: "home-user__title"
};
const _hoisted_11 = {
  class: "home-user__head"
};
const _hoisted_12 = ["src"];
const _hoisted_13 = {
  class: "phone"
};
const _hoisted_14 = {
  class: "uid"
};
const _hoisted_15 = {
  class: "balance"
};
const _hoisted_16 = {
  class: "btns"
};
const _hoisted_17 = {
  class: "menus"
};
import { router } from '@/router';
import { computed, ref } from 'vue';
import { useUserStore } from '@/store/user';
import { Logout } from '@/api';
import { showConfirmDialog, showToast } from 'vant';
import { hidePhoneNumber } from '@/utils';
import { baseURL } from '@/config';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
export default {
  __name: 'Home',
  setup(__props) {
    const {
      t
    } = useI18n();
    const {
      locale: i18nLanguage
    } = useI18n();
    const userStore = useUserStore();
    userStore.updateUserInfo();
    userStore.updateWallet();
    const toBooking = () => {
      router.push('/booking');
    };
    const user = computed(() => userStore.user);
    const wallet = computed(() => userStore.wallet);
    const show = ref(false);
    const showActionSheet = ref(false);
    const jump = path => {
      router.push(path);
    };
    const route = useRoute();
    const token = route.query.token;
    if (token) {
      userStore.setToken(token);
    }
    const _Logout = async () => {
      showConfirmDialog({
        title: t('System message'),
        message: t('Are you sure you want to log out?'),
        confirmButtonText: t('Confirm'),
        cancelButtonText: t('Cancel'),
        beforeClose: action => new Promise(resolve => {
          if (action !== 'confirm') {
            resolve(true);
            return false;
          }
          Logout().then(({
            code,
            msg
          }) => {
            resolve(code === 200);
            showToast(msg);
            if (code === 200) {
              userStore.setToken('');
              userStore.setUser({});
              router.push('/index');
            }
          });
        })
      });
    };
    const actions = [{
      name: 'Eng',
      value: 'en_US'
    }, {
      name: 'हिंदी',
      value: 'thi_IN'
    }];
    const tapActionSheet = item => {
      userStore.setLang(item.value);
      i18nLanguage.value = item.value;
      showActionSheet.value = false;
    };
    return (_ctx, _cache) => {
      const _component_van_popup = _resolveComponent("van-popup");
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
        src: _unref(baseURL) + '/storage/' + user.value.avatar,
        alt: "",
        onClick: _cache[0] || (_cache[0] = $event => show.value = true)
      }, null, 8, _hoisted_4), _createElementVNode("div", null, [_createElementVNode("p", null, "Hello, " + _toDisplayString(user.value.name), 1), _createElementVNode("p", null, "UID：" + _toDisplayString(user.value.uid), 1)])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", null, _toDisplayString(parseFloat(user.value.credit)), 1), _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        onClick: _cache[1] || (_cache[1] = $event => jump('/activity'))
      }), _cache[17] || (_cache[17] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1))])]), _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('we_help_brands_drive_more_revenue_without_increasing_marketing_costs')), 1), _cache[30] || (_cache[30] = _createElementVNode("div", {
        class: "home-video__box"
      }, null, -1)), _createElementVNode("div", {
        class: "home-button",
        onClick: _cache[2] || (_cache[2] = $event => toBooking())
      }, _toDisplayString(_ctx.$t('Booking')), 1), _createElementVNode("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('tour_packages')), 1), _cache[18] || (_cache[18] = _createElementVNode("div", {
        class: "home-list__content"
      }, [_createElementVNode("img", {
        src: _imports_2,
        alt: ""
      })], -1))]), _createVNode(_component_van_popup, {
        show: show.value,
        "onUpdate:show": _cache[14] || (_cache[14] = $event => show.value = $event),
        position: "left",
        style: {
          width: '70%',
          height: '100%'
        },
        round: ""
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_9, [_createElementVNode("p", _hoisted_10, [_cache[19] || (_cache[19] = _createElementVNode("img", {
          src: _imports_3,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('Polarsteps')), 1)]), _createElementVNode("div", _hoisted_11, [_createElementVNode("img", {
          class: "avatar",
          src: _unref(baseURL) + '/storage/' + user.value.avatar,
          alt: ""
        }, null, 8, _hoisted_12), _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(hidePhoneNumber)(user.value.phone)), 1)]), _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('UID')) + ":" + _toDisplayString(user.value.uid), 1), _createElementVNode("p", _hoisted_15, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('account_balance')) + ": ", 1), _createTextVNode("₹" + _toDisplayString(wallet.value.balance), 1)]), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", {
          onClick: _cache[3] || (_cache[3] = $event => jump('/deposit'))
        }, [_cache[20] || (_cache[20] = _createElementVNode("img", {
          src: _imports_4,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('cash_in')), 1)]), _createElementVNode("div", {
          onClick: _cache[4] || (_cache[4] = $event => jump('/withdraw'))
        }, [_cache[21] || (_cache[21] = _createElementVNode("img", {
          src: _imports_5,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('cash_out')), 1)])]), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", {
          class: "menus-item",
          onClick: _cache[5] || (_cache[5] = $event => jump('/level'))
        }, [_cache[22] || (_cache[22] = _createElementVNode("img", {
          src: _imports_6,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('LEVEL')), 1)]), _createElementVNode("div", {
          class: "menus-item",
          onClick: _cache[6] || (_cache[6] = $event => jump('/accountDetails'))
        }, [_cache[23] || (_cache[23] = _createElementVNode("img", {
          src: _imports_7,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('DETAILS')), 1)]), _createElementVNode("div", {
          class: "menus-item",
          onClick: _cache[7] || (_cache[7] = $event => showActionSheet.value = true)
        }, [_cache[24] || (_cache[24] = _createElementVNode("img", {
          src: _imports_8,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('Language')), 1)]), _createElementVNode("div", {
          class: "menus-item",
          onClick: _cache[8] || (_cache[8] = $event => jump('/aboutUs'))
        }, [_cache[25] || (_cache[25] = _createElementVNode("img", {
          src: _imports_9,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('about_us')), 1)]), _createElementVNode("div", {
          class: "menus-item",
          onClick: _cache[9] || (_cache[9] = $event => jump('/changePwd'))
        }, [_cache[26] || (_cache[26] = _createElementVNode("img", {
          src: _imports_10,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('help_centre')), 1)]), _createElementVNode("div", {
          class: "menus-item",
          onClick: _cache[10] || (_cache[10] = $event => jump('/contractUs'))
        }, [_cache[27] || (_cache[27] = _createElementVNode("img", {
          src: _imports_11,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('Customer Service')), 1)]), _createElementVNode("div", {
          class: "menus-item",
          onClick: _cache[11] || (_cache[11] = $event => jump('/bankList'))
        }, [_cache[28] || (_cache[28] = _createElementVNode("img", {
          src: _imports_12,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('Bind personal information')), 1)]), _createElementVNode("div", {
          class: "menus-item",
          onClick: _cache[12] || (_cache[12] = $event => jump('/signIn'))
        }, [_cache[29] || (_cache[29] = _createElementVNode("img", {
          src: _imports_13,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('Sign-In')), 1)])]), _createElementVNode("div", {
          class: "sign-out__btn",
          onClick: _cache[13] || (_cache[13] = $event => _Logout())
        }, _toDisplayString(_ctx.$t('Sign Out')), 1)])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_action_sheet, {
        show: showActionSheet.value,
        "onUpdate:show": _cache[15] || (_cache[15] = $event => showActionSheet.value = $event),
        actions: actions,
        "cancel-text": _ctx.$t('Cancel'),
        "close-on-click-action": "",
        onCancel: _cache[16] || (_cache[16] = $event => showActionSheet.value = false),
        onSelect: tapActionSheet
      }, null, 8, ["show", "cancel-text"])]);
    };
  }
};